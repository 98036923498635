import React from "react";
import { Switch, Route, } from "react-router-dom";
import { PropagateLoader } from "react-spinners";

// Pages
const Soon = React.lazy(() => import('./views/startscreens/soon/Soon'));
const Welcome = React.lazy(() => import('./views/startscreens/welcome/Welcome'));
const Login = React.lazy(() => import('./views/startscreens/login/Login'));
const Register = React.lazy(() => import('./views/startscreens/register/Register'));
const About = React.lazy(() => import('./views/pages/about/About'));
const Contact = React.lazy(() => import('./views/pages/contact/Contact'));
const Faq = React.lazy(() => import('./views/pages/faq/Faq'));
const Privacy = React.lazy(() => import('./views/pages/privacy/Privacy'));
const Terms = React.lazy(() => import('./views/pages/terms/Terms'));
const SendWhatsapp = React.lazy(() => import('./views/pages/sendWhatsapp/SendWhatsapp'));

const loading = (
  <div className="spinner-container">
    <PropagateLoader
      color={'#E71D8B'}
      loading
      size={20}
    />
  </div>
)

function App() {
  return (
    <React.Suspense fallback={loading}>
      <Switch>
        <Route exact path="/" name="Soon" render={props => <Soon {...props} />} />
        <Route exact path="/welcome" name="Welcome" render={props => <Welcome {...props} />} />
        <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
        <Route exact path="/register" name="Register" render={props => <Register {...props} />} />
        <Route exact path="/about" name="About" render={props => <About {...props} />} />
        <Route exact path="/contact" name="Contact" render={props => <Contact {...props} />} />
        <Route exact path="/faq" name="Faq" render={props => <Faq {...props} />} />
        <Route exact path="/privacy" name="Privacy" index render={props => <Privacy {...props} />} />
        <Route exact path="/terms" name="Terms" render={props => <Terms {...props} />} />
        <Route exact path="/sendWhatsapp" name="Terms" render={props => <SendWhatsapp {...props} />} />
      </Switch>
    </React.Suspense>
  );
}

export default App;
